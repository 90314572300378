<template>
  <div class="">
    <div class="flex items-center h-full  right-0 z-10 fixed">
      <button
        @click="toggle"
        class="bg-white sm:bg-greey w-6 md:w-10 py-1 text-black font-extrabold text-lg md:text-3xl rounded-l-xl flex justify-center items-center focus:outline-none"
      >
        +
      </button>
    </div>
    <section
      v-if="info"
      id="info"
      class="bg-5g inset-0 absolute z-50 overflow-y-scroll"
    >
      <div
        class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0"
      >
        <button
          @click="toggle"
          class="w-6 h-6 rounded-full left-0 focus:outline-none"
        >
          <svg
            :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 490.667 490.667"
            style="enable-background:new 0 0 490.667 490.667;"
            xml:space="preserve"
          >
            <path
              style="fill:white;"
              d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"
            />
            <path
              style="fill:#488c39;"
              d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"
            />
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-white text-lg">
            <div class="fontBold tracking-ls2 text-2xl md:mb-2">
              + {{ $t("almadar.project_info.project_info") }}
            </div>
            <br />
            {{ $t("almadar.project_info.work.1") }} /
            <br class="hidden lg:block" />
            {{ $t("almadar.project_info.work.2") }} /
            <br class="hidden lg:block" />
            {{ $t("almadar.project_info.work.3") }} /
            <br class="hidden lg:block" />
            {{ $t("almadar.project_info.work.4") }} /
          </div>
          <div class="lg:w-9/12 text-white mt-6">
            <p class="text-4xl fontBold ls">
              {{ $t("almadar.project_info.description.title") }}
            </p>

            <div class="">
              <p class="mt-6 md:text-justify leading-7 text-lg">
                {{ $t("almadar.project_info.description.content_1") }}
              </p>

              <p class="mt-6 md:text-justify leading-7 text-lg">
                {{ $t("almadar.project_info.description.content_2") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="hero"
      class="w-full h-64 md:h-auto lg:h-screen relative bg-center"
    >
      <div class="flex justify-center items-center w-full h-full bg-black relative">
        <iframe
          class="xl:h-vimeo h-screen md:h-96 w-full xl:w-vimeo"
          src="https://player.vimeo.com/video/491159534?h=5914afc9d7"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>

    <section id="Gif" class="-mt-1">
      <img
        src="../assets/images/almadar5G/GIF_INTRO.gif"
        alt="GIF_INTRO"
        class="w-full lg:h-screen"
      />
    </section>

    <section dir="ltr" id="future" class="flex items-center mx-auto">
      <div class="lg:w-3/12 ">
        <img src="../assets/images/almadar5G/VR_en.jpg" alt="" />
      </div>
      <div :dir="`${$i18n.locale}` == 'ar' ? 'rtl' : 'ltr'" class="lg:w-full py-20 max-w-xl">
        <p
          class="text-lg md:text-5xl lg:text-6xl fontBold ls2 lg:leading-almadar-line-height"
        >
          {{ $t("almadar.future.title") }}
        </p>
        <p class="text-sm md:text-base fontBold ls  mt-10">
          {{ $t("almadar.future.question") }}
        </p>
        <p class="text-xl md:text-basemt-4 font-normal leading-6">
          {{ $t("almadar.future.answer") }}
        </p>
      </div>
    </section>

    <section id="SKETCHES" class=" max-w-6xl mx-auto px-4 lg:px-0">
      <img src="../assets/images/almadar5G/SKETCHES.jpg" alt="" />
    </section>

    <section
      id="Stage_Design"
      class="mt-12 md:mt-24 max-w-6xl mx-auto px-4 lg:px-0"
    >
      <p class="text-center text-2xl md:text-5xl fontBold ls2">
        {{ $t("almadar.stage-design.text-1") }}
      </p>

      <img src="../assets/images/almadar5G/15@0.5x.png" alt="Stage_Design" />

      <img
        src="../assets/images/almadar5G/STAGE-2.jpg"
        alt="Stage_Design"
        class="mt-4"
      />
    </section>

    <section id="opportunities" class="mt-12 md:mt-24 ">
      <p class="text-center text-2xl md:text-5xl fontBold ls">
        {{ $t("almadar.stage-design.text-2") }}
      </p>

      <div class="bg-yellow">
        <div
          class="max-w-5xl mx-auto grid grid-cols-2 gap-x-4 md:gap-x-16 py-4 md:py-16  px-4 lg:px-0"
        >
          <img
            src="../assets/images/almadar5G/opportunities-2.jpg"
            alt=""
            class="shadow-2xl"
          />

          <img
            src="../assets/images/almadar5G/opportunities-1.jpg"
            alt=""
            class="shadow-2xl"
          />
        </div>
      </div>
      <div class="max-w-6xl mx-auto px-4 lg:px-0">
        <p class="text-xl md:text-justify  md:text-lg mt-8 max-w-xl mx-auto">
          {{ $t("almadar.stage-design.text-3") }}
        </p>

        <div class="mt-8 grid grid-cols-2 gap-x-4 md:gap-x-8">
          <img src="../assets/images/almadar5G/opportunities-3.jpg" alt="" />
          <img src="../assets/images/almadar5G/opportunities-4.jpg" alt="" />
        </div>
      </div>
    </section>

    <section id="dreams" class=" max-w-6xl mx-auto px-4 lg:px-0">
      <div class="max-w-5xl mx-auto grid md:grid-cols-2 gap-x-8 py-8 md:py-16">
        <p class="text-3xl md:text-6xl fontBold ls md:leading-12 ">
          {{ $t("almadar.dreams.title") }}
        </p>
        <p class="text-xl md:text-justify max-w-sm ">
          {{ $t("almadar.dreams.description") }}
        </p>
      </div>

      <img
        src="../assets/images/almadar5G/dreams.jpg"
        alt=""
        class="mt-4 w-full max-h-screen mb-8"
      />
    </section>
    <div
      class="text-xl md:text-3xl flex justify-center text-white bg-footer pt-4 linkwork fontRg -mt-1 -mb-1 z-10"
    >
      <router-link :to="`/${$i18n.locale}/AbnaYousef`">
        {{ $t("Next-Project") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: false,
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    scrollTo(0, 0);
  },
  methods: {
    toggle() {
      if (this.info == true) {
        this.info = false;
        document.body.style.overflow = "";
      } else {
        this.info = true;
        scrollTo(0, 0);
        document.body.style.overflow = "hidden";
      }
    },
  },
};
</script>

<style></style>
